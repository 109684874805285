import { UserOutlined } from "@ant-design/icons";
import { AdminPanelSettingsOutlined } from "@mui/icons-material";
import {
  Box, Button, CircularProgress, MenuItem, Select, SxProps, Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { Modal } from "components/common/Dialog";
import useToggle from "hooks/useToggle";
import { SkeletonMask } from "pages/Home/components/Widgets/components";
import { UsersLicenseStatus } from "services/settings/usersManagement";

export function UserRoleBadge({ role }: { role: "user" | "admin" }) {
  const isAdminUser = role === "admin";
  return (
    <Box
      sx={{
        backgroundColor: isAdminUser ? "#ffecee" : "#f0f0f0",
        color: isAdminUser ? "#ff4d4f" : "#262626",
        borderRadius: "20px",
        fontWeight: "semibold",
        textTransform: "capitalize",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2px 10px",
        fontSize: "12px",
        gap: "5px",
      }}
    >
      {isAdminUser ? (
        <AdminPanelSettingsOutlined sx={{ fontSize: "12px", verticalAlign: "middle" }} />
      ) : (
        <UserOutlined style={{ fontSize: "12px", verticalAlign: "middle" }} />
      )}
      <span>
        {role}
      </span>
    </Box>
  );
}

export enum UserInvitationStatus {
  TO_INVITE = "not invited",
  PENDING = "invited",
  ACCEPTED = "accepted",
  REMOVED = "removed"
}

const UserInvitationStatusBackgroundColor = {
  [UserInvitationStatus.TO_INVITE]: "#f5f6f7",
  [UserInvitationStatus.PENDING]: "#d9f5fd",
  [UserInvitationStatus.ACCEPTED]: "#e9f5cf",
  [UserInvitationStatus.REMOVED]: "#f9d6d6",
};

const UserInvitationStatusTextColor = {
  [UserInvitationStatus.TO_INVITE]: "#636363",
  [UserInvitationStatus.PENDING]: "#2b77c2",
  [UserInvitationStatus.ACCEPTED]: "#436f00",
  [UserInvitationStatus.REMOVED]: "#ff4d4f",
};

interface UserInvitationStatusBadgeProps {
  status: UserInvitationStatus;
  onInvite: () => Promise<void>
  onRemove: () => Promise<void>
}

const getInvitationColors = (status: UserInvitationStatus) => ({
  backgroundColor: UserInvitationStatusBackgroundColor[status],
  color: UserInvitationStatusTextColor[status],
});

export function InvitationStatusBadge({ status, onInvite, onRemove }: UserInvitationStatusBadgeProps) {
  const [isLoading, , afterAwiatToggle] = useToggle();
  const { backgroundColor, color } = getInvitationColors(status);

  const onToggleInvitationStatus = async () => {
    const action = status === UserInvitationStatus.TO_INVITE ? onInvite : onRemove;
    await afterAwiatToggle(action());
  };

  const hoverColor = getInvitationColors(status === UserInvitationStatus.TO_INVITE ? UserInvitationStatus.PENDING : UserInvitationStatus.REMOVED)?.color;
  return (
    <Box
      onClick={onToggleInvitationStatus}
      sx={{
        backgroundColor,
        color,
        outline: `1px solid ${color}`,
        borderRadius: "20px",
        fontWeight: "bold",
        textTransform: "capitalize",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        padding: "2px 10px",
        fontSize: "12px",
        gap: "5px",
        cursor: "pointer",
        "--invite": "none",
        "--remove": "none",
        "--default": "block",
        "&:hover": {
          "--invite": status === UserInvitationStatus.TO_INVITE ? "block" : "none",
          "--remove": (status === UserInvitationStatus.PENDING) ? "block" : "none",
          "--default": "none",
          backgroundColor: "transparent",
          color: hoverColor,
        },
      }}
    >
      {isLoading && (
        <CircularProgress size="18px" sx={{ color: "currentcolor", display: isLoading ? "block" : "none" }} />
      )}
      <span style={{ display: "var(--invite)" }}>Invite</span>
      <span style={{ display: "var(--remove)" }}>Remove</span>
      <span style={{ display: "var(--default)" }}>{status}</span>
    </Box>
  );
}

interface HeaderCellProps {
  children: React.ReactNode;
  width?: string;
  sx?: SxProps
}
export function HeaderCell({ children, width = "50px", sx }: HeaderCellProps) {
  return (
    <Box sx={{
      color: "#262626",
      maxWidth: width,
      minWidth: width,
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      userSelect: "none",
      padding: "0 10px",
      whiteSpace: "nowrap",
      ...sx,
    }}
    >
      {children}
    </Box>
  );
}

interface ConfirmModalProps {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export function ConfirmDialog(props: ConfirmModalProps) {
  const {
    title, description, isOpen, onClose, onConfirm,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const _onConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose} containerSx={{ maxWidth: "550px" }}>
      <Box sx={{
        padding: "20px", display: "flex", flexDirection: "column", gap: "20px",
      }}
      >
        <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>{title}</Box>
        <Box sx={{ fontSize: "14px" }}>{description}</Box>
        <Box sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}>
          <Button onClick={onClose} variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={_onConfirm} variant="contained" disabled={isLoading} sx={{ gap: "5px" }}>
            {isLoading ? (
              <>
                <CircularProgress size="18px" sx={{ color: "currentcolor" }} />
                <span>
                  Confirm
                </span>
              </>
            ) : (
              <span>
                Confirm
              </span>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

interface InvitationConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  selectedUsers: string[];
}

export function InvitationConfirmDialog(props: InvitationConfirmDialogProps) {
  return (
    <ConfirmDialog
      {...props}
      title="Invite Selected Users"
      description={`There are ${props.selectedUsers.length} users selected. Are you sure you want to invite them?`}
    />
  );
}

type RemoveConfirmDialogProps = InvitationConfirmDialogProps;
export function RemoveConfirmDialog(props: RemoveConfirmDialogProps) {
  return (
    <ConfirmDialog
      {...props}
      title="Remove Selected Users"
      description="Are you sure you want to remove these users?"
    />
  );
}

export function AvailableLicensesBadge({ licensesStatus }: { licensesStatus?: UsersLicenseStatus }) {
  return (
    <Box sx={{
      display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", zIndex: 2,
    }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", zIndex: 2 }}>
        <h3>Users Management</h3>
        <span>Control your team members and their access to the platform</span>
      </Box>
      <SkeletonMask loading={!licensesStatus}>
        <Box sx={{
          display: "flex", fontSize: "17px", fontWeight: "semibold", flexDirection: "row", gap: "10px", zIndex: 2, borderRadius: "10px", padding: "10px", background: "#d9f5fd",
        }}
        >
          {/* you have x licences, and you have used y of them */}
          <Typography sx={{ fontSize: "14px", fontWeight: "semibold", gap: "5px" }}>
            You have used
            <span style={{
              fontSize: "16px", fontWeight: "bold", margin: "0 5px", color: "#2b77c2",
            }}
            >
              {(licensesStatus?.totalLicenses ?? 0) - (licensesStatus?.remainingLicenses ?? 0)}
            </span>
            licences out of
            <span style={{
              fontSize: "16px", fontWeight: "bold", margin: "0 5px", color: "#2b77c2",
            }}
            >
              {licensesStatus?.totalLicenses ?? 0}
            </span>
          </Typography>
        </Box>
      </SkeletonMask>
    </Box>
  );
}

interface InviteByEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (emails: string[], userType: string) => Promise<void>;
}
enum UserType {
  Admin = "admin",
  User = "user"
}
export const InviteByEmailModal = ({ isOpen, onClose, onInvite }: InviteByEmailModalProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [userType, setUserType] = useState<UserType>(UserType.User);
  const [isLoading, , afterAwaitToggle] = useToggle();

  const onInviteByEmail = async () => {
    const cleanEmails = emails.filter((email) => email.length > 0);
    await afterAwaitToggle(onInvite(cleanEmails, userType));
    onClose();
  };

  const onReset = () => {
    setEmails([]);
    onClose();
  }

  const changeUserType = (type: UserType) => {
    setUserType(type);
  }

  useEffect(() => {
    if (!isOpen) {
      onReset();
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} containerSx={{ maxWidth: "550px" }}>
      <Box sx={{
        padding: "20px", display: "flex", flexDirection: "column", gap: "20px",
      }}
      >
        <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>Invite Users by Email</Box>
        <Box sx={{ fontSize: "14px" }}>
          Enter the email addresses of the users you want to invite. Separate multiple emails with a comma.
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <textarea
            placeholder="Email addresses"
            value={emails.join(", ")}
            onChange={(e) => setEmails(e.target.value.split(",").map((email) => email.trim()))}
            style={{
              padding: "10px", borderRadius: "5px", border: "1px solid #d9d9d9", fontSize: "14px", resize: "none",
            }}
          />
          <Box sx={{ fontSize: "12px", color: "#636363" }}>
            Separate multiple emails with a comma. Example:
            {" "}
            <span style={{ color: "#262626" }}>
              example@example.com, example2@example.com
            </span>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
          <Button onClick={onClose} variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontSize: "14px" }}>Invite as:</Typography>
              <Select value={userType} sx={{ height: '36px', width: '110px' }} onChange={(e: any) => changeUserType(e?.target?.value as UserType)}>
                <MenuItem value={UserType.User}>User</MenuItem>
                <MenuItem value={UserType.Admin}>Admin</MenuItem>
              </Select>
            </Box>
            <Button onClick={onInviteByEmail} variant="contained" disabled={isLoading} sx={{ gap: "5px" }}>
              {isLoading ? (
                <>
                  <CircularProgress size="18px" sx={{ color: "currentcolor" }} />
                  <span>
                    Invite
                  </span>
                </>
              ) : (
                <span>
                  Invite
                </span>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}


